"use client";

import {
	Box,
	BoxProps,
} from "@mui/material";
import { ReactNode } from "react";
const SellerNavBarWrapper = ({
	border,
	children,
	...props
}: BoxProps & {
	border: number;
	elevation: number;
	children: ReactNode;
}) => {
	return (
		<Box
			{...props}
			sx={{
				...props.sx,
				height: "60px",
				width: "100%",
				borderRadius: "0px",
				position: "relative",
				...(border && {
					borderBottom: (theme) =>
						`1px solid ` +
						theme.palette.grey[400],
				}),
			}}>
			{children}
		</Box>
	);
};

export default SellerNavBarWrapper;
