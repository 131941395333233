"use client";

import {
	Box,
	Chip,
} from "@mui/material";
import { FlexBox } from "components/flex-box";
import { NavLink2 } from "components/nav-link";
import { useAccount } from "contexts/AccountContext";
import {
	usePathname,
	useRouter,
} from "next/navigation";
import {
	FC,
	useEffect,
	useRef,
} from "react";
import {
	CLEANOUT_CONSOLE_CASHOUT_ROOT,
	CLEANOUT_CONSOLE_ROOT,
	CLEANOUT_ROOT,
} from "utils/config";
import SellerNavbarInnerContainer from "./SellerNavbarInnerContainer";
import SellerNavBarWrapper from "./SellerNavbarWrapper";

// ==========================================================
export type SellerNavbarProps = {
	border?: number;
	elevation?: number;
	variant?: "link" | "chip";
};
// ==========================================================

const SellerNavbar: FC<
	SellerNavbarProps
> = ({
	elevation = 2,
	border = 3,
	variant = "chip",
}) => {
	const { customer } = useAccount();
	const router = useRouter();
	const isConsignor =
		customer?.is_consignor;
	const actualPath = usePathname();

	const navRefs = useRef({});

	useEffect(() => {
		// Scroll the active nav into view if it exists
		if (
			actualPath &&
			navRefs.current[actualPath]
		) {
			navRefs.current[
				actualPath
			].scrollIntoView({
				behavior: "smooth",
				inline: "center",
				block: "center",
			});
		}
	}, [actualPath]);

	return (
		<SellerNavBarWrapper
			elevation={elevation}
			border={border as number}>
			<SellerNavbarInnerContainer>
				<FlexBox gap={2}>
					{sellerNavs.map((nav) => {
						if (
							nav.onlyMembers &&
							!customer?.has_account
						) {
							return null;
						}
						if (
							nav.onlyConsignors &&
							!isConsignor
						) {
							return null;
						}

						const isActive =
							actualPath === nav.href;
						if (
							isActive &&
							navRefs.current[nav.href]
						) {
							navRefs.current[
								nav.href
							].scrollIntoView({
								behavior: "smooth",
								inline: "center",
								block: "center",
							});
						}

						return (
							<Box
								key={nav.title}
								// @ts-ignore
								ref={(el) =>
									(navRefs.current[
										nav.href
									] = el)
								} // Assign ref
							>
								{variant === "link" ? (
									<NavLink2
										key={nav.title}
										url={nav.href}
										title={nav.title}
									/>
								) : (
									<Chip
										key={nav.title}
										label={nav.title}
										onClick={() => {
											router.push(
												nav.href
											);
										}}
										clickable
										color={
											isActive
												? "secondary"
												: "default"
										}
									/>
								)}
							</Box>
						);
					})}
				</FlexBox>
			</SellerNavbarInnerContainer>
		</SellerNavBarWrapper>
	);
};

export default SellerNavbar;

export const sellerNavs: {
	title: string;
	href: string;
	onlyMembers?: boolean;
	onlyConsignors?: boolean;
}[] = [
	{
		title: "Tableau de Bord",
		href: CLEANOUT_CONSOLE_ROOT,
		onlyConsignors: true,
		onlyMembers: true,
	},
	{
		title: "Guide de Vente",
		href: CLEANOUT_ROOT,
	},
	{
		title: "Prix et Gains",
		href:
			CLEANOUT_ROOT +
			"#pricing-and-earnings",
	},
	{
		title: "Normes de Qualité",
		href:
			CLEANOUT_ROOT +
			"#quality-standards",
	},
	{
		title: "Marques Inéligibles",
		href:
			CLEANOUT_ROOT +
			"#ineligible-brands",
	},
	{
		title: "Retirer de l'argent",
		href: CLEANOUT_CONSOLE_CASHOUT_ROOT,
		onlyMembers: true,
	},
];
