"use client";

import {
	Container,
	ContainerProps,
} from "@mui/material";
import { ReactNode } from "react";

const SellerNavbarInnerContainer = ({
	children,
	...props
}: ContainerProps & {
	children: ReactNode;
}) => {
	return (
		<Container
			{...props}
			maxWidth='lg'
			sx={{
				...props.sx,
				height: "100%",
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				overflowX: "auto",
				whiteSpace: "nowrap",
				// "&::-webkit-scrollbar": {
				// 	display: "none",
				// },
			}}>
			{children}
		</Container>
	);
};

export default SellerNavbarInnerContainer;
