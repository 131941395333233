"use client";

import { H6 } from "components/Typography";
import { useBackendConstants } from "contexts/BackendConstantsContext";
import useFreeShippingThreshold from "hooks/useFreeShippingThreshold";
import Link from "next/link";
import { FC } from "react";
import {
	FAQ_RETURNS_AND_FUNDS_PAGE_URL,
	FAQ_SHIPPING_FEE_PAGE_URL,
	FAQ_TRY_AND_BUY_LATER_PAGE_URL,
} from "utils/config";
import TopbarContainer from "./TopbarContainer";
import TopbarWrapper from "./TopbarWrapper";

// ===========================================
type TopbarProps = { bgColor?: string };
// ===========================================

const Topbar: FC<TopbarProps> = ({
	bgColor,
}) => {
	const freeShippingThreshold =
		useFreeShippingThreshold();

	const {
		return: {
			delay_in_days: delayInDays,
		},
	} = useBackendConstants();

	return (
		<TopbarWrapper
			bgColor={bgColor ?? ""}>
			<TopbarContainer>
				<H6 component='span'>
					<Link
						href={
							FAQ_TRY_AND_BUY_LATER_PAGE_URL
						}
						title="Avec ce mode de paiement,  il vous sera demandé de saisir les données de votre carte bancaire lors de la confirmation de l'achat, mais vous ne serez débité qu'après avoir reçu et essayé les articles."
						//  open in new tab
						// target='_blank'
						// rel='noopener noreferrer'
					>
						Essayez d&rsquo;abord, payez
						après
					</Link>
				</H6>
				<H6 component='span'>
					<Link
						href={
							FAQ_SHIPPING_FEE_PAGE_URL
						}
						title={`*La livraison est gratuite pour les commandes supérieures à ${freeShippingThreshold}. Pour les commandes inférieures à ce montant, des frais de livraison s'appliquent.`}
						//  open in new tab
						// target='_blank'
						// rel='noopener noreferrer'
					>
						{`Livraison  offerte à
						partir de ${freeShippingThreshold} et retours
						gratuits*`}
					</Link>
				</H6>
				<H6 component='span'>
					<Link
						href={
							FAQ_RETURNS_AND_FUNDS_PAGE_URL
						}
						title={`Vous avez ${delayInDays} jours pour faire votre choix et nous renvoyer les articles non portés.`}
						//  open in new tab
						// target='_blank'
						// rel='noopener noreferrer'
					>
						Retour et remboursement
					</Link>
				</H6>
			</TopbarContainer>
		</TopbarWrapper>
	);
};

export default Topbar;
